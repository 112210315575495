import data from './data'
import actions from './actions'
import useToast from '@/utils/toast'
import {
  syncronizeParamsWithUrl,
  replaceRouterQuery,
} from '@/utils/syncUrl'

export default () => {
  const { makeToast } = useToast()
  const categoryOptions = [
  ]

  const fetchItems = () => {
    data.isBusy.value = true
    actions.fetchItems({
      ...data.searchParams.value,
    }).then(response => {
      const resData = response.data
      data.items.value = resData.data
      data.totalRows.value = resData.page_info.total_count
      data.filterParams.value.total = resData.page_info.total_count
      replaceRouterQuery(data.searchParams.value)
    }).catch(() => {
      makeToast('danger', '목록을 쿼리하는데 실패 했습니다')
    }).finally(() => {
      data.isBusy.value = false
    })
  }

  const search = params => {
    data.currentPage.value = 1
    data.filterParams.value = {
      ...params,
    }
    syncronizeParamsWithUrl(data.searchParams.value)
    fetchItems()
  }

  const tableColumns = [
    { key: 'idx', label: 'idx' },
    { key: 'title', label: 'title' },
    { key: 'state', label: 'state' },
    { key: 'created_at', label: 'created_at' },
  ]

  const changePage = params => {
    data.currentPage.value = params.page
    fetchItems()
  }

  return {
    categoryOptions,
    ...data,
    fetchItems,
    tableColumns,
    search,
    changePage,
  }
}
